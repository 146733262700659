<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">Votaciones</h2>

          <b-list-group>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/votos-coalicion"
            >
              Votos en Coalición
            </b-list-group-item>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/casillas-electorales"
            >
              Casillas Electorales
            </b-list-group-item>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/participacion-ciudadana"
            >
              Participación Ciudadana
            </b-list-group-item>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/lista-nominal"
              active
            >
              Lista Nominal
            </b-list-group-item>
          </b-list-group>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Votaciones
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                Lista Nominal
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            Lista Nominal
          </h2>

          <p class="text-center">
            Son las relaciones que contienen el nombre y fotografía de las
            personas incluidas en el Padrón Electoral, agrupadas por distrito
            y sección, a quienes se ha entregado su credencial para votar vigente.
          </p>

          <div class="row align-items-center my-5">
            <div class="col-12 col-lg-4 mb-3">
              <img src="https://ayuda.ine.mx/2024/informate/assets/img/votaciones/nominal-1.svg" alt="" class="img-fluid d-block mx-auto">
            </div>
            <div class="col-12 col-lg-4 mb-3">
              <p>
                La Lista Nominal está agrupada por Distrito y Sección.
              </p>

              <p>
                Las secciones en que se dividen los Distritos uninominales
                tienen como mínimo 100 y como máximo 3,000 electores.
              </p>
            </div>
            <div class="col-12 col-lg-4 mb-3">
              <img src="https://ayuda.ine.mx/2024/informate/assets/img/votaciones/nominal-2.svg" alt="" class="img-fluid d-block mx-auto">
            </div>
          </div>

          <div class="row seccion-color align-items-center">
            <div class="col-12 col-lg-6 my-4">
              <p>
                <b>
                  Mediante la lista se define el número de casillas que se instalan por sección.
                  Por cada 750 electores inscritos en la lista nominal, se instala una casilla.
                </b>
                <br />
                <br />

                Ejemplo: Si una sección tiene <b>2250</b> electores,
                se dividen entre <b>750</b>, dando como resultado
                la instalación de <b>3</b> casillas.
              </p>
            </div>
            <div class="col-12 col-lg-6 my-4">
              <img src="https://ayuda.ine.mx/2024/informate/assets/img/votaciones/nominal-3.svg" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>

          <p>
            La <b>Dirección Ejecutiva del Registro Federal de Electores (DERFE)</b>,
            elabora e imprimelas Listas Nominales de electores definitivas de las y
            los ciudadanos incluidos en el Padrón Electoral hasta el último día de febrero de 2024.
          </p>

          <p>
            Realiza la entrega de la lista por lo menos treinta días antes de la Jornada a:
          </p>

          <div class="row align-items-start">
            <div class="col-12 col-lg-4 mb-3">
              <img src="https://ayuda.ine.mx/2024/informate/assets/img/votaciones/nominal-4.svg" alt="" class="img-fluid mx-auto d-block">

              <p>
                Los Consejos Locales distribuyen a
              </p>
            </div>
            <div class="col-12 col-lg-4 mb-3">
              <img src="https://ayuda.ine.mx/2024/informate/assets/img/votaciones/nominal-5.svg" alt="" class="img-fluid mx-auto d-block">

              <p>
                Los Consejos Distritales distribuyen a
              </p>
            </div>
            <div class="col-12 col-lg-4 mb-3">
              <img src="https://ayuda.ine.mx/2024/informate/assets/img/votaciones/nominal-6.svg" alt="" class="img-fluid mx-auto d-block">

              <p>
                Las Mesas Directiva de casillas para el cotejo de los
                datos de las y los ciudadanos que emiten su voto.
              </p>
            </div>
          </div>

          <p class="mb-5">
            Así como a los Partidos Políticos y Candidaturas Independientes,
            exclusivamente para su revisión.
          </p>

          <p class="text-center mb-5">
            <b>
              Referencias:
            </b>
            <br />
            · Ley General de Instituciones y Procedimientos Electorales, Artículos 147, 153 y 253.
          </p>

          <a
            href="https://ayuda.ine.mx/2024/informate/assets/docs/Votaciones/Lista_Nominal.pdf"
            target="_blank"
            class="btn btn-primary mx-auto d-block"
          >
            Descarga Infografía
          </a>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
}

.list-group-item {
  z-index: 0;
}

.grid {
  display: grid;
  align-items: center;
  gap: 45px;
  grid-template-columns: 50px 1fr;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }
}
</style>
